<template>
  <div class="course-detail">
    <div class="d-flex justify-content-between">
      <div class="mt-2">
        <h6 class="text-primary">Course Details</h6>
      </div>
      <div class="d-flex justify-content-end">
        <span
          class="btn action-button"
          v-c-tooltip="{ content: 'Save', placement: 'left' }"
          @click="updateCourseDetail"
        >
          <CIcon
            name="cil-save"
            class="active text-primary"
            style="margin-right: 20px"
          />
        </span>
      </div>
    </div>
    <CRow>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Course Type</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <Select
                name="course_test_type_id"
                :value="courseDetail.course_test_type_id"
                :options="
                  options && options['course_type']
                    ? options['course_type']
                    : []
                "
                :multiple="false"
                :clearable="false"
                @input="handleChangeSelect"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol lg="4" v-if="isUG_PG">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12"
            >Healthcare Sector/Department</label
          >
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="org_dept_id"
              :value="courseDetail.org_dept_id"
              :options="
                options && options['orgDepartment']
                  ? options['orgDepartment']
                  : []
              "
              :multiple="false"
              :clearable="false"
              @input="handleChangeSelect"
            />
          </div>
        </CRow>
      </CCol>
      <CCol lg="4" v-else>
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12"
            >Healthcare Sector/Department</label
          >
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="healthcare_sector"
              :value="courseDetail.healthcare_sector"
              :options="
                options && options['courseHealthcareSector']
                  ? options['courseHealthcareSector']
                  : []
              "
              :multiple="false"
              :clearable="false"
              @input="handleChangeSelect"
            />
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Student Type</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <Select
                name="candidate_type_ids"
                :value="courseDetail.candidate_type_ids"
                :options="
                  options && options['candidateType']
                    ? options['candidateType']
                    : []
                "
                :multiple="true"
                :clearable="false"
                @input="handleChangeMultiSelect"
                :error="errors[0]"
                :limit="1"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Course Level</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider
              ref="courseLevel"
              rules="required"
              v-slot="{ errors }"
            >
              <Select
                name="course_level_id"
                :value="courseDetail.course_level_id"
                :options="
                  options && options['courseLevel']
                    ? options['courseLevel']
                    : []
                "
                :multiple="false"
                :clearable="false"
                @input="handleChangeSelect"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Course Reference</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <TextInput
              name="course_ref"
              :value="courseDetail.course_ref"
              @input="handleInput"
            />
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required"
            >Course Title (Full name)</label
          >
          <div class="col-lg-12 col-md-12 col-sm-12">
            <TextInput
              name="course_title"
              :value="courseDetail.course_title"
              @input="handleInput"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">CPD Points</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="cpd_points"
              :value="courseDetail.cpd_points"
              :options="
                options && options['cpdPoints'] ? options['cpdPoints'] : []
              "
              :multiple="false"
              :clearable="true"
              @input="handleChangeSelect"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Start Date</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <DatePicker
              name="start_date"
              :value="courseDetail.start_date"
              @input="handleDatePicker"
              format="DD-MM-YYYY"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">End Date</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <DatePicker
              name="end_date"
              :value="courseDetail.end_date"
              @input="handleDatePicker"
              format="DD-MM-YYYY"
            />
          </div>
        </CRow>
      </CCol>
      <!-- <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Duration Access(Months)</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="numeric" v-slot="{ errors }">
              <TextInput
                name="duration_months"
                :value="courseDetail.duration_months"
                @input="handleInput"
                type="number"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol> -->
      <!-- <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Country</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <Select
                name="country_id"
                :value="courseDetail.country_id"
                :options="
                  options && options['locations'] ? options['locations'] : []
                "
                :error="errors[0]"
                :clearable="false"
                @input="handleChangeSelect"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol> -->
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Qualification</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="qualification_id"
              :value="courseDetail.qualification_id"
              :options="
                options && options['qualifications']
                  ? options['qualifications']
                  : []
              "
              @input="handleChangeSelect"
            />
          </div>
        </CRow>
      </CCol>
      <CCol lg="4" v-if="isUG && isShowTrainArea">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Training Area</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <Select
                name="speciality_id"
                :value="courseDetail.speciality_id"
                :options="
                  options && options['courseSpeciality']
                    ? options['courseSpeciality']
                    : []
                "
                :multiple="false"
                :clearable="false"
                @input="handleChangeSelect"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Language Delivery</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="language_id"
              :value="courseDetail.language_id"
              :options="
                options && options['languages'] ? options['languages'] : []
              "
              :multiple="false"
              :clearable="false"
              @input="handleChangeSelect"
            />
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Study Mode</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="study_mode"
              :value="courseDetail.study_mode"
              :options="
                options && options['studyMode'] ? options['studyMode'] : []
              "
              :multiple="true"
              :clearable="true"
              @input="handleChangeMultiSelect"
            />
          </div>
        </CRow>
      </CCol>
      <CCol lg="4" v-if="isUG_PG">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">ISCED</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="isced_level_id"
              :value="courseDetail.isced_level_id"
              :options="options && options['ISCED'] ? options['ISCED'] : []"
              :multiple="false"
              :clearable="false"
              @input="handleChangeSelect"
            />
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Description</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <TextAreaInput
              name="description"
              :value="courseDetail.description"
              @input="handleInput"
              :rows="3"
            />
          </div>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import CourseViewMixin from "@/containers/Course/ViewCourse/CourseViewMixin.js";
import { mapActions } from "vuex";
import m from "moment";
export default {
  name: "EduCourseDetailView",
  mixins: [CourseViewMixin],
  props: {
    courseDetailInfo: {
      type: Object,
      default: () => {},
    },
    courseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      courseDetail: {},
      payload: {},
    };
  },
  computed: {
    options() {
      return {
        course_type: this.courseTypes,
        languages: this.locationLanguageReOrder,
        courseHealthcareSector: this.courseHealthcareSector,
        orgDepartment: this.getOrgDepartments,
        candidateType: this.studentType,
        courseLevel: this.isHasCandidateType
          ? this.getCourseQualClassification
          : [],
        locations: this.locationCountryInfo,
        qualifications: this.qualificationByCountryAndCandidateType,
        cpdPoints: this.CPDPoints,
        studyMode: this.trainingTypes,
        courseSpeciality: this.getCourseSpeciality,
        ISCED: this.getISCEDLevels,
      };
    },
    isAHP() {
      return this.payload?.candidate_type_ids?.includes(3);
    },
    customSpeciality() {
      return this.getCourseSpeciality.map((v) => ({
        code: v.code,
        label: this.isAHP ? v.alt_speciality_name : v.label,
      }));
    },
    CPDPoints() {
      const points = Array.from({ length: 250 }, (_, i) => i + 1).map((v) => ({
        code: v,
        label: v,
      }));
      return [{ code: null, label: "N/A" }, ...points];
    },
    isUG_PG() {
      return [2, 3].includes(this.payload?.course_test_type_id) || false;
    },
    isUG() {
      return this.payload?.course_test_type_id === 2 || false;
    },
    isShowTrainArea() {
      return (
        [2, 3].some((v) => this.payload?.candidate_type_ids?.includes(v)) ||
        false
      );
    },
    isHasCandidateType() {
      return this.payload?.candidate_type_ids?.length || false;
    },
  },
  methods: {
    ...mapActions([
      "updateCourse",
      "fetchCourseQualification",
      "fetchQualificationByQuery",
      "fetchQualificationClassification",
      "fetchCourseSpeciality",
    ]),
    handleChangeMultiSelect(name, value) {
      const val = value.map((v) => {
        return v.id || v.code || v;
      });
      Vue.set(this.courseDetail, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? val : null,
      };
      if (name === "candidate_type_ids") {
        this.handleChangeSelect("course_level_id", null);
        this.$nextTick(() => {
          this.$refs.courseLevel.errors.clear;
          this.$refs.courseLevel.reset();
        });
        if (val?.length)
          this.fetchQualificationClassification({
            candidate_type_id__in: val,
            sort_level__lte: 100,
          });
        this.fetchSpeciality(val);
        this.fetchQualification();
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.courseDetail, name, value);
      const val = value ? value.id || value.code : null;
      this.payload = {
        ...this.payload,
        [name]: val,
      };

      if (name === "course_test_type_id") {
        if (this.isUG_PG) this.handleChangeSelect("healthcare_sector", null);
        else {
          this.handleChangeSelect("org_dept_id", null);
          this.handleChangeSelect("isced_level_id", null);
        }
      }
      if (["course_level_id"].includes(name)) {
        this.fetchQualification();
      }
    },
    fetchSpeciality(candidate_type_ids) {
      const candidate_type_id = candidate_type_ids?.[0];
      this.handleChangeSelect("speciality_id", null);
      if ([1, 4].includes(candidate_type_id)) {
        const speciality_id = {
          1: {
            code: 403,
            label: "In Training",
          },
          4: {
            code: 137,
            label: "In Training",
          },
        };
        this.handleChangeSelect(
          "speciality_id",
          speciality_id[candidate_type_id]
        );
      } else {
        const is_training = candidate_type_id === 2;
        let payload = { candidate_type_id, is_training };
        this.fetchCourseSpeciality(payload);
      }
    },
    fetchQualification() {
      const { candidate_type_ids, course_level_id } = this.payload;
      if (candidate_type_ids && course_level_id) {
        const country_ids = this.organisationCountryIds?.join();
        const data = {
          candidate_type_id__in: candidate_type_ids,
          qualification_classification_id: course_level_id,
          country_id__in: `${country_ids},null`,
        };
        this.fetchQualificationByQuery(data);
      }
    },
    handleInput(name, value) {
      Vue.set(this.courseDetail, name, value);
      Vue.set(this.payload, name, value);
    },
    handleDatePicker(name, value) {
      Vue.set(this.courseDetail, name, value);
      Vue.set(this.payload, name, m(value).format("YYYY-MM-DD"));
    },
    updateCourseDetail() {
      const payload = {
        ...this.payload,
        course_provider: this.courseDetailInfo?.course_provider,
      };
      this.updateCourse({ training_course_id: this.courseId, payload });
    },
    initCourseDetail() {
      const data = this.courseDetailInfo;
      const courseType = data?.course_test_types
        ? {
            code: data?.course_test_type_id,
            label: data?.course_test_types?.course_test_type,
          }
        : null;
      this.handleChangeSelect("course_test_type_id", courseType);

      const healthcareSector = data.healthcare_sector
        ? {
            code: data.healthcare_sector,
            label: _.startCase(data.healthcare_sector),
          }
        : null;
      this.handleChangeSelect("healthcare_sector", healthcareSector);

      const departmentInfo = data?.department
        ? {
            code: data.department.organisation_department_id,
            label: data.department.department.department_name,
          }
        : null;
      this.handleChangeSelect("org_dept_id", departmentInfo);

      const candidate_type_id = data?.candidate_types?.length
        ? data?.candidate_types.map((v) => ({
            code: v?.candidate_type_id,
            label: v?.candidate_type,
          }))
        : null;
      this.handleChangeMultiSelect("candidate_type_ids", candidate_type_id);

      const course_level = data?.course_level
        ? {
            code: data?.course_level?.qualification_classification_id,
            label: data?.course_level?.qualification_classification,
          }
        : null;
      this.handleChangeSelect("course_level_id", course_level);

      const iscedLevel = data?.isced_level
        ? {
            code: data?.isced_level?.isced_id,
            label: `${data?.isced_level?.level}-${data?.isced_level?.description}`,
          }
        : null;
      this.handleChangeSelect("isced_level_id", iscedLevel);

      const study_mode = data?.study_mode_name?.length
        ? data?.study_mode_name?.map((v) => ({
            code: v.id,
            label: v.study_mode,
          }))
        : [];
      this.handleChangeMultiSelect("study_mode", study_mode);

      const start_date = data?.start_date
        ? m(data?.start_date, "YYYY-MM-DD").toDate()
        : null;
      this.handleDatePicker("start_date", start_date);

      const end_date = data?.end_date
        ? m(data?.end_date, "YYYY-MM-DD").toDate()
        : null;
      this.handleDatePicker("end_date", end_date);

      const country_id = data?.country
        ? {
            code: data?.country?.country_id,
            label: data?.country?.country_name,
          }
        : null;
      this.handleChangeSelect("country_id", country_id);

      const qualification_id = data?.qualification
        ? {
            code: data?.qualification?.qualification_id,
            label: data?.qualification?.qualification,
          }
        : null;
      this.handleChangeSelect("qualification_id", qualification_id);

      const speciality_id = data?.speciality
        ? {
            code: data?.speciality?.speciality_id,
            label: this.isAHP
              ? data?.speciality?.alt_speciality_name ||
                data?.speciality?.speciality
              : data?.speciality?.speciality,
          }
        : null;
      this.handleChangeSelect("speciality_id", speciality_id);

      this.handleInput("course_ref", data.course_ref);
      this.handleInput("course_title", data.course_title);
      const language = data.language_id
        ? { code: data.language_id, label: data?.language?.language }
        : null;
      this.handleChangeSelect("language_id", language);
      this.handleInput("cefr_level", data.cefr_level);
      this.handleInput("course_synopsis", data.course_synopsis);
      this.handleInput("learning_hours", data.learning_hours);
      this.handleInput("duration_months", data.duration_months);
      const cpd_points = data?.cpd_points
        ? {
            code: data?.cpd_points,
            label: data?.cpd_points,
          }
        : { code: null, label: "N/A" };
      this.handleChangeSelect("cpd_points", cpd_points);
    },
  },
  mounted() {
    this.initCourseDetail();
  },
};
</script>
