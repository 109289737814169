import _ from "lodash";
import Vue from "vue";
import m from "moment";

import { extend } from "vee-validate";
import {
    required,
    numeric,
    email,
    confirmed,
    regex,
    min,
    alpha_spaces,
} from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("numeric", { ...numeric, message: "Only numbers are allowed" });

import { mapGetters, mapActions } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import Modal from "@/components/reusable/Modal";
import DatePicker from "@/components/reusable/Fields/DatePicker";
export default {
    components: {
        Select,
        TextInput,
        RadioButton,
        TextAreaInput,
        Modal,
        DatePicker
    },
    computed:{
        ...mapGetters([
            "getCourseTestTypes",
            "locationLanguageReOrder",
            "courseDeliveryMode",
            "courseHealthcareSector",
            "getCurrency",
            "getOrgIDFromAccessToken",
            "trainingTypes",
            "courseInfo",
            "getOrgDepartments",
            "candidateType",
            "getCourseQualifications",
            "qualificationByCountryAndCandidateType",
            "getLanguageExam",
            "locationCountryInfo",
            "getQualificationClassification",
            "getCourseQualClassification",
            "getISCEDLevels",
            "getCourseSpeciality",
            "organisationCountryIds"
        ]),
        studentType() {
            const nonMedical =6
            return this.candidateType.filter(v=> v.code !=nonMedical) || []
        },
        courseTypes() {
            return (
                this.getCourseTestTypes.map((v) => ({
                    code: v?.course_test_type_id,
                    label: v?.course_test_type,
                })) || []
            );
        },
        getLanguageTest() {
            return this.getLanguageExam
                .map((val) => ({
                    code: val?.test_id,
                    label:
                        val?.test_acronym && val?.test_name
                            ? `${val.test_acronym}-${val.test_name}`
                            : val?.test_name,
                    test_acronym: val?.test_acronym,
                }))
        },
    },
    methods:{
        ...mapActions(["showToast"]),
        tooltipHide() {
            setTimeout(() => {
              let x = document.querySelectorAll(".tooltip-old");
              for (var i = 0; i < x.length; i++) {
                x[i].style.visibility = "hidden";
              }
            }, 50);
          },
    }
}