<template>
  <div class="course-view">
    <LoadingOverlay v-if="isFetching" />
    <div class="view-course container-md col-lg-10 mb-4" v-else-if="courseInfo">
      <CCard class="mt-2">
        <CCardBody>
          <EduCourseDetail v-if="isEducationalIns" :courseDetailInfo="courseDetail" :courseId="courseId" />
          <CourseDetail v-else :courseDetailInfo="courseDetail" :courseId="courseId" />
        </CCardBody>
      </CCard>
      <CCard class="mt-2" v-if="isEducationalIns">
        <CCardBody>
          <SemesterDetail
            :semesterInfo="semesterInfo"
            :courseId="courseId"
          />
        </CCardBody>
      </CCard>
      <CCard class="mt-2" >
        <CCardBody>
          <CourseOption
            :courseOptionsInfo="courseOption"
            :courseId="courseId"
          />
        </CCardBody>
      </CCard>
      <CCard class="mt-2">
        <CCardBody>
          <CourseModule
            :courseModulesInfo="courseModule"
            :courseId="courseId"
          />
        </CCardBody>
      </CCard>
 
      <CCard class="mt-2" v-if="!isEducationalIns">
        <CCardBody>
          <div class="d-flex justify-content-between">
            <div class="mt-2">
              <h6 class="text-primary">Course Templates</h6>
            </div>
            <div class="d-flex justify-content-end" v-if="courseDetail">
              <router-link
                v-if="!courseTemplate?.length"
                :to="`/add-course-template/${courseDetail.training_course_id}`"
              >
                <CButton
                  class="btn-primary small mr-2"
                  style="height: 28px"
                  v-c-tooltip="'Click to Assign new Template!'"
                  >Add</CButton
                >
              </router-link>
              <CButton
                v-else
                class="btn-primary small mr-2"
                style="height: 28px"
                v-c-tooltip="'Click to view Template!'"
                @click="previewTemp"
                >View</CButton
              >
              <!-- <CButton
              v-if="courseTemplate?.length"
                class="btn-primary small mr-2"
                style="height: 28px"
                v-c-tooltip="'Click to Edit Template!'"
                @click="editTemp"
                >Edit</CButton
              > -->
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>
    <div v-else class="center-div">
      <p class="h3 text-center text-muted">No Data Found</p>
    </div>
    <CModal
      v-if="templatePreview.isShowPopup"
      :show.sync="templatePreview.isShowPopup"
      :closeOnBackdrop="false"
      :centered="true"
      color="primary"
      size="lg"
    >
      <template #header>
        <h6>Preview</h6>
        <button aria-label="Close" class="close" @click="closeModal">x</button>
      </template>
      <TemplatePreview :templateData="templatePreview.data" />
    </CModal>

    <CModal
      v-if="templateEditModal.isShowPopup"
      :show.sync="templateEditModal.isShowPopup"
      color="primary"
      size="xl"
      :closeOnBackdrop="false"
    >
      <template #header>
        <h6>Edit Course Template</h6>
        <button aria-label="Close" class="close" @click="editModalCallback(false)">x</button>
      </template>
      <AddTemplate :templateInfo="courseTemplate" />
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import CourseDetail from "@/containers/Course/ViewCourse/CourseDetailView";
import CourseOption from "@/containers/Course/ViewCourse/CourseOption";
import CourseModule from "@/containers/Course/ViewCourse/CourseModule";
import { mapActions, mapGetters } from "vuex";
import LoadingOverlay from "../../LoadingOverlay.vue";
import TemplatePreview from "@/components/reusable/CourseTemplateView";
import AddTemplate from "@/containers/CourseTemplate/AddTemplate.vue";
import EduCourseDetail from "@/containers/Course/ViewCourse/EduCourseDetailView";
import SemesterDetail from "@/containers/Course/ViewCourse/SemesterDetail";
export default {
  name: "CourseView",
  components: {
    CourseDetail,
    CourseOption,
    CourseModule,
    LoadingOverlay,
    TemplatePreview,
    AddTemplate,
    EduCourseDetail,
    SemesterDetail
  },
  data() {
    return {
      isFetching: false,
      templatePreview: {
        isShowPopup: false,
        data: null,
      },
      templateEditModal: {
        isShowPopup: false
      }
    };
  },
  computed: {
    ...mapGetters(["courseInfo", "courseTemplate", "getOrgTypeIDFromAccessToken"]),
    courseDetail() {
      return this.courseInfo?.training_course;
    },
    courseOption() {
      return this.courseInfo?.training_course_options;
    },
    courseModule() {
      return this.courseInfo?.training_course_modules;
    },
    semesterInfo() {
      return this.courseInfo?.course_semesters;
    },
    courseId() {
      return this.courseDetail?.training_course_id;
    },
    isEducationalIns(){
      return this.getOrgTypeIDFromAccessToken === 18
    }
  },
  methods: {
    ...mapActions([
      "initCourseOptions",
      "getCourseById",
      "fetchCourseTemplate",
    ]),
    editTemp(){
      this.templateEditModal.isShowPopup = true;
    },
    initAction() {
      const { id } = this.$route.params;
      if (id) {
        this.isFetching = true;
        let appendAction = [this.initCourseOptions()];
        appendAction = [...appendAction, this.getCourseById(id)];
        appendAction = [...appendAction, this.fetchCourseTemplate(id)];
        Promise.all(appendAction).then((res) => {
          this.isFetching = false;
        });
      }
    },
    previewTemp() {
      this.templatePreview.data = this.courseTemplate;
      this.templatePreview.isShowPopup = true;
    },
    closeModal() {
      this.templatePreview.isShowPopup = false;
    },
    editModalCallback(action, data){
      this.templateEditModal.isShowPopup = false;
    }
  },
  mounted() {
    this.initAction();
  },
};
</script>
<style lang="scss" scoped>
.center-div {
  width: 300px;
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
</style>
