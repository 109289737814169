<template>
  <div class="course-module">
    <div class="d-flex justify-content-between">
      <div class="mt-2">
        <h6 class="text-primary">
          Course Modules
          <span class="h6">
            [{{ courseModulesInfo.length || "0" }} record{{
              courseModulesInfo.length > 1 ? "s" : ""
            }}]</span
          >
        </h6>
      </div>
      <div>
        <CButton
          class="btn-primary small mx-2"
          style="height: 28px"
          v-c-tooltip="'Click to Add new module!'"
          v-if="!isEdit"
          @click="addNewModule"
          >Add</CButton
        >
        <span
          class="btn action-button mx-2"
          v-c-tooltip="{ content: 'Save', placement: 'left' }"
          v-if="isEdit"
          @click="submitModule"
        >
          <CIcon
            name="cil-save"
            class="active text-primary"
            style="margin-right: 20px"
          />
        </span>
        <span
          class="btn action-button mx-2"
          v-c-tooltip="{ content: 'Cancel', placement: 'left' }"
          v-if="isEdit"
          @click="cancelEdit"
        >
          <CIcon
            name="cil-x-circle"
            class="active text-primary"
            style="margin-right: 20px"
          />
        </span>
      </div>
    </div>
    <div v-if="isEdit" class="border-bottom" ref="form">
      <ValidationObserver ref="moduleForm" v-slot="{ handleSubmit }">
        <form id="moduleForm" @submit.prevent="handleSubmit(onSubmit)">
          <CRow>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12 required">Module No.</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <TextInput
                      name="module_no"
                      :value="editModule.module_no"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
            </CCol>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12 required">Title</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <TextInput
                      name="module_title"
                      :value="editModule.module_title"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">Content</label>
                <div class="col-lg-12 col-md-12">
                  <TextAreaInput
                    name="module_content"
                    :value="editModule.module_content"
                    @input="handleInput"
                  />
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">Related Test</label>
                <div class="col-lg-12 col-md-12">
                  <RadioButton
                    name="related_test"
                    :value="editModule.related_test"
                    :options="
                      options && options['boolean'] ? options['boolean'] : []
                    "
                    @change="handleRadioButton"
                  />
                </div>
              </CRow>
            </CCol>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">Required Test Score</label>
                <div class="col-lg-12 col-md-12">
                  <ValidationProvider rules="numeric" v-slot="{ errors }">
                    <TextInput
                      name="required_test_score"
                      :value="editModule.required_test_score"
                      type="number"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
            </CCol>
            <CCol md="4">
              <CRow class="mb-3">
                <label class="col-lg-12 col-md-12">Required Test Percent</label>
                <div class="col-lg-12 col-md-12">
                  <ValidationProvider rules="numeric" v-slot="{ errors }">
                    <TextInput
                      name="required_test_percent"
                      :value="editModule.required_test_percent"
                      type="number"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
            </CCol>
          </CRow>
        </form>
      </ValidationObserver>
    </div>
    <div class="multi-records">
      <div
        v-for="(item, index) in courseModulesInfo"
        :key="index"
        class="record border-dotted-bottom px-3 pt-1"
      >
        <div class="d-flex justify-content-between">
          <div class="w-98">
            <CRow>
              <CCol md="4">
                <p class="head">Module No.</p>
                <p class="data">{{ item.module_no || "--" }}</p>
              </CCol>
              <CCol md="4">
                <p class="head">Title</p>
                <p class="data">{{ item.module_title | startCase }}</p>
              </CCol>
              <CCol md="4">
                <p class="head">Content</p>
                <p
                  class="data text-truncate"
                  v-c-tooltip="{
                    content: `${item.module_content}`,
                    placement: 'left',
                  }"
                >
                  {{ item.module_content || "--" }}
                </p>
              </CCol>
              <CCol md="4">
                <p class="head">Related Test</p>
                <p class="data">{{ item.related_test |radioResponse }}</p>
              </CCol>
              <CCol md="4">
                <p class="head">Required Test Score</p>
                <p class="data">{{ item.required_test_score || "--" }}</p>
              </CCol>
              <CCol md="4">
                <p class="head">Required Test Percent</p>
                <p class="data">
                  {{ item.required_test_percent || "--" }}
                </p>
              </CCol>
            </CRow>
          </div>
          <div class="text-center">
            <CButton
              class="icon icon-edit mb-2"
              v-c-tooltip="{ content: 'Edit', placement: 'left' }"
              @click="enableEditModule(item)"
            >
            </CButton>
            <!-- <CButton
              class="icon"
              v-c-tooltip="{ content: 'Delete', placement: 'left' }"
              @click="deleteModule(item)"
            >
              <i class="fas fa-trash-alt" />
            </CButton> -->
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import Vue from "vue";
import CourseViewMixin from "@/containers/Course/ViewCourse/CourseViewMixin.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CourseViewModule",
  mixins: [CourseViewMixin],
  props: {
    courseModulesInfo: {
      type: Array,
      default: () => [],
    },
    courseId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      isEdit: false,
      editModule: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
    };
  },
  computed: {
    options() {
      return {
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        courseDeliveryMode: this.courseDeliveryMode,
        currency: this.getCurrency,
        trainingTypes: this.trainingTypes,
      };
    },
  },
  methods: {
    ...mapActions(["updateCourseModule", "createCourseModule"]),
    submitModule() {
      this.tooltipHide();
      const payload = {
        ...this.editModule,
        training_course_id: this.courseId,
      };
      const { training_course_mod_id } = this.editModule;
      let appendAction = [];
      if (training_course_mod_id) {
        appendAction = [
          this.updateCourseModule({ payload, training_course_mod_id }),
        ];
      } else {
        appendAction = [this.createCourseModule(payload)];
      }
      Promise.all(appendAction).then((res) => {
        this.cancelEdit();
      });
    },
    addNewModule() {
      this.editModule = {};
      this.isEdit = true;
      this.tooltipHide();
    },
    cancelEdit() {
      this.isEdit = false;
      this.tooltipHide();
      this.editModule = null;
    },
    enableEditModule(data) {
      this.tooltipHide();
      this.editModule = {
        ...data,
      };
      this.isEdit = true;
      setTimeout(() => {
        this.$refs.form?.scrollIntoView({ behavior: "smooth" });
      }, 10);
    },
    deleteModule(data) {
      this.deleteConfirmModal.modalTitle = "NOTIFICATION";
      this.deleteConfirmModal.modalContent =
        "Are you sure you want to delete this Module?";
      this.deleteConfirmModal.isShowPopup = true;
      this.deleteConfirmModal.data = data;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        const { data } = this.deleteConfirmModal;
      }
      this.deleteConfirmModal.isShowPopup = false;
      this.deleteConfirmModal.data = null;
    },
    handleInput(name, value) {
      Vue.set(this.editModule, name, value);
    },
    handleRadioButton(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.editModule, name, val);
    },
  },
  filters: {
    startCase(data) {
      return data ? _.startCase(data) : data;
    },
    radioResponse(data) {
      const opt = {
        true: "Yes",
        false: "No",
      };
      return opt[data] || "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.w-98 {
  width: 98%;
}
.head {
  font-size: 12px;
  color: grey;
  margin: 6px 0;
}
.data {
  color: #000;
  font-weight: 400;
}
.multi-records .record {
  border-top: none;
  background: hsla(0, 0%, 83.5%, 0.12941176470588237);
  border-radius: 6px;
  border-bottom: 2px dotted #e1dede;
  margin-bottom: 3px;
}
.action-button .c-icon {
  width: 30px;
  height: 25px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
</style>
